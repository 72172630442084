@import '../../styles/customMediaQueries.css';

:root {
  --showPanelWidth: 380px;
}

.root {
}

.topbarContent {
  max-width: var(--marketplacePageWidth);
  margin: 0 auto;

  padding: 0 24px;
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

/* Content */
.contentContainer {
  display: flex;
  flex-direction: column;
  max-width: var(--marketplacePageWidth);
  margin: 0 auto 72px;

  @media (--viewportMedium) {
    margin: 0 auto 72px;
  }

  @media (--viewportLarge) {
    margin: 48px auto 72px;
    padding: 0 24px;
  }

  @media (--viewportLargeWithPaddings) {
    margin: 0 auto 72px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

/* Modals */
.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
  border-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.modalButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Style */
  background-color: var(--marketplaceColor);
  margin-top: 32px;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.descriptionModalContainer {
  composes: marketplaceModalBaseStyles from global;

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

/* SectionImages */
.sectionImages {
  position: relative;

  /* Bg */
  background-image: linear-gradient(to left top, #000000, #000000, #320327, #320327);
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 120%; /* 1:1 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.rootForImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadiusMedium);
}

.imageOverlay {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  /* Layout */
  height: 100%;
  width: 100%;

  /* Style */
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 100%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: var(--borderRadiusMedium);
}

.heroContentWrapper {
}

.heroContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  max-width: var(--marketplacePageWidth);
  width: 100%;
  margin: 0 auto;
  padding: 24px 24px 36px;

  @media (--viewportMedium) {
    height: auto;
    padding: 24px;
  }

  @media (--viewportLarge) {
    padding: 48px 24px 72px;
  }
}

.listingInfo {
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    gap: 24px;
  }

  @media (--viewportLarge) {
    gap: 48px;
  }
}

.titleContainer {
  /* Position */
  position: relative;

  /* Layout */
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: listingPageHeroTitle from global;
  color: var(--matterColorLight);

  /* Layout */
  margin: 12px 0 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.topDescriptionWrapper {
  width: 100%;
}

.topDescription {
  /* Font */
  color: var(--matterColorLight);
  margin-top: 12px;

  width: 100%;
}

.topDescriptionShowMore {
  /* Layout */
  display: flex;
  align-items: center;

  /* Borders */
  border: none;

  /* Font */
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);

  /* Text size should be inherited */
  text-decoration: underline;
  text-underline-position: auto;

  /* Margin & Padding */
  margin: 6px 0 0;
  padding: 0;

  /* Effect */
  transition: all var(--transitionStyleButton);

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.listingData {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.listingDataMainInfo {
  display: flex;
  align-items: center;
  flex: 1;
}

.listingDataMainInfo,
.listingDataMainInfoColumn {
  width: 100%;
}

.topReviews {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;

  /* Size */
  width: 100%;

  /* Margin & Padding */
  margin-top: 24px;
  padding-top: 24px;

  /* Style */
  border-top: 1px solid var(--matterColorLight);

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;

    margin-top: 24px;
    padding-top: 24px;
  }

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    width: auto;
  }
}

.topReviewsSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.topReviewsHeading {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;
}

.topReviewsValue {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 42px;
    line-height: 48px;
  }
}

.showRatingStar {
  fill: var(--marketplaceColor);
}

.organizationProfile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.organizationProfile {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
  width: fit-content;

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    &:hover {
      opacity: 0.8;
    }
  }
}

.organizationProfileLoadingText {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Font */
    color: var(--matterColorLight);
    font-weight: var(--fontWeightMedium);
  }
}

.listingImage {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Position */
    position: relative;

    /* Size */
    width: 230px;

    /* Style */
    border-radius: var(--borderRadiusMedium);
  }
}

.listingImageAspectRatioWrapper {
  background-color: var(--matterColorNegative);
  border-radius: var(--borderRadiusLarge);
}

.listingImageRoot {
  composes: rootForImage;
}

.listingImageMobile {
  width: 230px;
  margin-right: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.textSeparator {
  color: var(--matterColorLight);
  margin: 0 8px;
}

.author {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    padding-bottom: 48px;
  }
}

.viewPhotos {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  /* Position */
  position: absolute;
  bottom: -1px;
  right: 24px;

  /* Layout */
  display: flex;
  align-items: center;
  padding: 6px 12px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    /* Font */
    color: var(--matterColorLight);
    text-decoration: underline;
    text-underline-position: auto;

    /* Position */
    position: unset;
    bottom: unset;
    right: unset;

    /* Layout */
    padding: 0;

    /* Style */
    background-color: unset;
    border-radius: unset;

    &:hover {
      opacity: 0.8;
    }
  }
}

.viewPhotosMask {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  /* Position */
  position: absolute;
  bottom: -1px;
  right: 24px;

  /* Layout */
  display: flex;
  align-items: center;
  padding: 6px 12px;

  /* Style */
  background-color: var(--matterColorLight);
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    /* Font */
    color: var(--matterColorLight);

    /* Position */
    position: unset;
    bottom: unset;
    right: unset;

    /* Layout */
    padding: 0;

    /* Style */
    background-color: unset;
    border-radius: unset;
  }
}

.authorHeading {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Font */
    color: var(--matterColorLight);
    font-weight: var(--fontWeightMedium);
    text-decoration: underline;
    text-underline-position: auto;

    /* Effect */
    transition: var(--transitionStyleButton);

    &:hover {
      opacity: 0.8;
    }
  }
}

.authorActions {
  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;

  @media (--viewportMedium) {
    justify-content: center;
    gap: 18px;
    width: auto;
  }
}

.authorAction {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    text-decoration: underline;
    padding: 0;

    background-color: unset;
    border-radius: unset;

    &:hover {
      opacity: 0.8;
    }
  }
}

.authorActionIcon {
  & path,
  & g path {
    stroke: var(--matterColor);

    @media (--viewportMedium) {
      stroke: var(--matterColorLight);
    }
  }
}

.editIcon {
  margin-top: -1px;

  & path {
    stroke-width: 1.3;
  }
}

.galleryIcon {
  height: 18px;
  width: 18px;

  margin-right: 8px;

  & path {
    fill: none;
    stroke: var(--matterColor);

    @media (--viewportMedium) {
      stroke: var(--matterColorLight);
    }
  }
}

/* SectionHighlights */
.highlightsAndPanelSections {
  @media (--viewportLarge) {
    display: flex;

    width: calc(100% - var(--showPanelWidth));
    padding-right: 24px;
  }

  @media (--viewportLargeWithPaddings) {
    padding-right: 0;
  }
}

.sectionHighlights {
  /* Layout */
  width: 100%;
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    min-width: 100%;
  }
}

.sectionHighlightsHidden {
  opacity: 0;
  visibility: none;
}

.highlightsTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

.highlights {
  width: 100%;
}

.highlightsSliderItems,
.topExperiencesSliderItems {
  @media (--viewportLarge) {
    &:after {
      content: '';
      display: block;
      background-color: transparent;
      border: 8px solid transparent;
    }
  }
}

.highlightsSliderArrows,
.topExperiencesSliderArrows {
  @media (--viewportLarge) {
    margin-right: 16px;
  }
}

.videoPlayerControlText {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* SectionTopExperiences */
.sectionTopExperiences {
  /* Layout */
  width: 100%;
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    min-width: 100%;
  }
}

.topExperiencesTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

/* SectionAddVideos */
.sectionAddVideos {
  /* Layout */
  width: 100%;
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    min-width: 100%;
  }
}

.addVideos {
  /* Layout */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.addVideo {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 130%;

  /* Position */
  position: relative;

  /* Style */
  background: linear-gradient(
    -180deg,
    var(--matterColorLight) 0%,
    var(--matterColorLight) 50%,
    var(--marketplaceColorLight) 100%
  );
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
}

.addVideoContent {
  /* Position */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Layout */
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  /* Font */
  text-align: center;
}

.addVideoIcon {
  height: 36px;
  width: 36px;
}

.addVideoButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  /* Font */
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  text-transform: capitalize;

  /* Layout */
  padding: 12px;

  /* Style */
  background-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
}

/* SectionDetails */
.sectionDetails {
  margin-top: 48px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.descriptionDetails {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-top: 32px;
  }
}

.detailsTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.details {
  display: flex;
  flex-direction: column;
}

.detail {
  padding-top: 12px;
  padding-bottom: 12px;

  border-top: 1px solid var(--matterColorNegative);
}

.detailHeading {
  font-weight: var(--fontWeightMedium);
  margin-right: 12px;
}

/* SectionDescription */
.sectionDescription {
  padding: 0 24px;
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.descriptionTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* SectionReviews */
.sectionReviews {
  margin-top: 48px;
  padding: 0 24px;

  @media (--viewportMedium) {
    /* Reset */
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    max-width: calc(100% - var(--showPanelWidth) - 48px);

    margin-left: 24px;
    padding: 0;
  }

  @media (--viewportLargeWithPaddings) {
    margin-left: 0;
  }
}

.sectionReviewsWithButton {
  margin-bottom: 0;
}

.reviewsHeadingContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Margin */
  margin-top: 0;
  margin-bottom: 24px;
}

.reviewsHeading {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    & .reviewsHeadingIcon {
      fill: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.reviewsHeadingIcon {
  margin-top: 2px;

  & path {
    stroke-width: 1.5;
  }
}

.reviewButton {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Size */
  height: 36px;
  padding: 0 16px;
  border-radius: calc(var(--borderRadiusMedium) * 2 + 2px);

  /* Font */
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColorLight);
    color: var(--matterColorLight);
    text-decoration: none;
  }
}

/* SectionHosts */
.sectionHostsMaybe {
  padding: 0 24px;

  margin-top: 48px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 72px;
    margin-bottom: 24px;
  }

  @media (--viewportLarge) {
    padding: 0;
    margin-left: 24px;
  }

  @media (--viewportLargeWithPaddings) {
    margin-left: 0;
  }
}

.hostsTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 0;
  margin-bottom: 24px;

  &:hover {
    & .hostsHeadingIcon {
      fill: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.hostsHeadingIcon {
  margin-top: 2px;

  & path {
    stroke-width: 1.5;
  }
}

.hosts {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    max-width: calc(100% - var(--showPanelWidth) - 48px);
  }
}

.host {
  /* Font */
  text-decoration: none !important;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-right: 0;
    background: none;
    border-radius: none;
  }
}

.hostAvatar {
  flex-shrink: 0;

  @media (--viewportMedium) {
    height: 96px;
    width: 96px;
  }
}

.hostContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hostHeading {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.hostInfoText {
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);

  margin-top: 0;
  margin-bottom: 0;
}

/* SectionHost */
.sectionHostMaybe {
  margin-top: 48px;
  padding: 0 24px;

  @media (--viewportMedium) {
    /* Reset */
    margin-top: 72px;
  }
  @media (--viewportLarge) {
    max-width: calc(100% - var(--showPanelWidth) - 48px);

    padding: 0;
    margin-left: 24px;
  }

  @media (--viewportLargeWithPaddings) {
    margin-left: 0;
  }
}

.yourHostHeading {
  composes: hostsTitle;
}

.yourHostHeadingIcon {
  composes: hostsHeadingIcon;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* SectionExperiences */
.sectionExperiences {
  margin-top: 48px;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.experiencesTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

/* SectionSimilarShows */
.sectionSimilarShows {
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}

.similarShowsTitle {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

/* Slider */
.sliderHeader {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

/* ShowPanel */
.showPanel {
  @media (--viewportLarge) {
    /* Position */
    position: relative;

    /* Layout */
    display: flex;
    margin-top: -72px;
    padding: 32px;
    z-index: 3;

    /* Style */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: var(--borderRadiusMedium);

    /* The calendar needs var(--showPanelWidth) */
    width: 100%;
    max-width: var(--showPanelWidth);
    flex-basis: var(--showPanelWidth);
    flex-shrink: 0;
  }
}

.fullWidthContainer {
  min-width: 100%;
  width: 100%;
}

.modalLink {
  composes: button buttonFont buttonText buttonBordersSecondary buttonColorsSecondary from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Font */
  text-align: center;
}

.modalLinkWithMargin {
  margin-top: 24px;
}
